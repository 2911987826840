import React from 'react'
import './App.css';

const VideoList = ({ handleButtonClick }) => {
  const data = [
    {
      id: 0,
      url: "https://icd.o2-favoriten.de/video_0.m3u8",
    },
    {
      id: 1,
      url: "https://icd.o2-favoriten.de/video_1.m3u8",
    },
    {
      id: 2,
      url: "https://icd.o2-favoriten.de/video_2.m3u8",
    },
    {
      id: 3,
      url: "https://icd.o2-favoriten.de/video_3.m3u8",
    },
    {
      id: 4,
      url: "https://icd.o2-favoriten.de/video_4.m3u8",
    },
    {
      id: 5,
      url: "https://icd.o2-favoriten.de/video_5.m3u8",
    },
    {
      id: 6,
      url: "https://icd.o2-favoriten.de/video_6.m3u8",
    },
  ]

  return (
    <div>
      {data.map(element => {
        if (element.id > 0) {
          return <button className={`play-btn play-btn-${element.id}`} key={element.id} onClick={() => handleButtonClick(element.url)}></button>
        }
        return null;
      })}
    </div>
  )
}

export default VideoList;