import React, { forwardRef } from 'react';
import ReactPlayer from 'react-player';
import './App.css';

const VideoPlayer = forwardRef((props, ref) => {
  const { url, playing, isDefaultVideo, handleEnd } = props;

  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        ref={ref}
        url={url}
        width='100%'
        height='100%'
        controls={false}
        playing={playing}
        muted={true} // must be mute for chrome to autoplay
        loop={isDefaultVideo}
        onEnded={() => handleEnd()}
        config={{
          file: {
            forceHLS: true
          }
        }}
      />
    </div>
  )
});

export default VideoPlayer;