import React, { useState, useRef } from 'react'
import screenfull from 'screenfull';
import './App.css';
import VideoPlayer from './VideoPlayer';
import VideoList from './VideoList';
import rendering from './assets/rendering_background_3.jpg';

const App = () => {
  const [url, setUrl] = useState('https://icd.o2-favoriten.de/video_0.m3u8');
  const [playing, setPlaying] = useState(true);
  const [isDefaultVideoPlaying, setIsDefaultVideoPlaying] = useState(true);
  const videoPlayerReference = useRef(null);
  const isDefaultVideo = url === 'https://icd.o2-favoriten.de/video_0.m3u8' ? true : false;

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(videoPlayerReference.current.wrapper);
    }
  };

  const handleButtonClick = (url) => {
    setUrl(url)
  }

  // check if default video is playing
  // if default video ends - loop it. if any other video ends - do not loop but switch to default video
  const playDefaultVideo = () => {
    //play default video
    console.log("video ended")
    setUrl('https://icd.o2-favoriten.de/video_0.m3u8');
    setPlaying(true);
    setIsDefaultVideoPlaying(isDefaultVideo);
  }

  return (
    <div className="app">
      <div className="wrapper">
        <img className="rendering" src={rendering} alt="" />
        <div className="container">
          <VideoPlayer ref={videoPlayerReference} url={url} playing={playing} setPlaying={setPlaying} setUrl={setUrl} handleEnd={playDefaultVideo} isDefaultVideo={isDefaultVideo} />
        </div>
        <VideoList handleButtonClick={handleButtonClick} />
        <button onClick={playDefaultVideo} className="screensaver-btn btn">Screensaver</button>
        <button onClick={handleClickFullscreen} className="fullscreen-btn btn">Fullscreen</button>
      </div>
    </div>
  );
}

export default App;
